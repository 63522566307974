<template>
  <div class="login">
    <van-nav-bar class="nav">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="wrapper">
      <div class="logoImg">
        <img src="../../assets/imgs/logo1.png" height="80%" width="80%" style="margin-left: -1rem;">
      </div>
      <div class="logoImg2">
        <img src="../../assets/imgs/logo2.png" height="100%" width="100%">
      </div>



      <div class="loginForm">
        มีบัญชีอยู่แล้ว?
      <br>
      บัญชี
        <van-field v-model="username" clearable input-align="center" class="input" placeholder="ป้อนบัญชีของคุณ" />
        <!-- :placeholder="$t('auth.username_place')" /> -->
        รหัสผ่าน
        <van-field v-model="password" :type="passwordType" input-align="center" class="input" placeholder="ป้อนรหัสผ่านของคุณ" />
        <!-- :placeholder="$t('auth.pwd_place')" /> -->
        <div class="reset-text">
          <!-- <span>{{ $t("auth.forgetpwd") }}?</span> -->
          <span>ลืมรหัสผ่าน?</span>
        </div>
        <van-button class="login-btn" type="primary" size="normal" @click="doLogin()">
          <!-- {{ $t("auth.login") }} -->เข้าสู่ระบบ
        </van-button>
        <van-divider contentPosition="center" customStyle="color: #000; border-color: #000; font-size: 18px;">
          หรือ
        </van-divider>

        <van-button @click="toRegister()" class="regist-btn">
          <!-- {{ $t("auth.no_account") }} -->ลงทะเบียน
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "inputValue",
    event: "input",
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      username: "",
      lang: 'en_us',
      password: this.inputValue,
      passwordType: "password",
    };
  },
  mounted() {
    // localStorage.setItem("lang", 'ms_my');
    this.lang = localStorage.getItem("lang") || 'th_th';
  },
  methods: {
    switchPasswordType() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    back() {
      return window.history.back();
    },
    toRegister() {
      this.$router.push("Register");
    },
    doLogin() {
      if (
        this.username === "" ||
        this.username === null ||
        this.username === undefined
      ) {
        this.$toast(this.$t("auth.username_place"));
        return false;
      }
      if (
        this.password === "" ||
        this.password === null ||
        this.password === undefined
      ) {
        this.$toast(this.$t("auth.pwd_place"));
        return false;
      }
      this.$http({
        url: "member_login",
        method: "post",
        data: {
          username: this.username,
          password: this.password,
          lang: this.lang
        },
      }).then((res) => {
        if (res.code === 200) {
          this.$toast.success(res.msg);
          localStorage.setItem("token", res.data.id);
          this.$router.push("home");
        } else {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (localStorage.getItem("token")) {
      return window.history.back();
    }
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.login {
  height: 100%;
  background-color: #fff;
}

.login .nav {
  background: #000;
}

.language {
  position: absolute;
  top: 4px;
  right: 0;
  height: 80px;

  img {
    height: 100%;
  }
}

.login .wrapper {
  height: calc(100% - 1.22667rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.login .wrapper .title {
  line-height: 1.86667rem;
  text-align: center;
  font-size: .48rem;
  font-weight: 700;
  color: #fff;
  letter-spacing: .05333rem;
}

.login .wrapper .login-input-border .input {
  border: .02667rem solid #747474
}

.login .wrapper .loginForm {
  padding: 0 .8rem
}

.login .wrapper .loginForm .input {
  padding: .26667rem .4rem;
  margin-top: .53333rem;
  text-align: center;
  line-height: .69333rem;
  font-size: .4rem;
  color: #4e4e4e;
  border-bottom: solid 0.03rem #000;
}

.login .wrapper .loginForm .input .left-icon {
  color: #fff
}

.login .wrapper .loginForm .input .right-icon {
  color: #979799
}

.login .wrapper .loginForm .reset-text {
  margin: .4rem .26667rem;
  display: flex;
  align-items: center;
  justify-content: flex-end
}

.login .wrapper .loginForm .reset-text span {
  color: #000;
  font-size: .32rem;
  font-weight: 500;
  line-height: .42667rem
}

.login .wrapper .loginForm .password-text {
  margin: .53333rem 0;
  display: flex;
  align-items: center
}

.login .wrapper .loginForm .password-text span {
  color: #fff;
  font-size: .37333rem;
  font-weight: 500;
  line-height: .42667rem
}

.login .wrapper .loginForm .password-text>span[data-v-53069c29]:first-child {
  margin-right: .4rem
}

.login .wrapper .loginForm .login-btn {
  margin-top: .8rem;
  width: 100%;
  height: 1.22667rem;
  line-height: 1.22667rem;
  background: #000000;
  border-radius: 1rem;
  color: #fff;
  font-size: .42667rem;
  font-weight: bolder;
  border: none
}

.login .wrapper .loginForm .login-btn.active {
  color: #282828;
  background-color: #e6b98a
}

.login .wrapper .loginForm .regist-btn {
  width: 100%;
  height: 1.22667rem;
  line-height: 1.22667rem;
  background: #fff;
  border: #000 solid 0.08rem;
  border-radius: 1rem;
  color: #000;
  font-size: .37333rem;
  font-weight: bolder;
}

.van-divider {
  margin: 1rem 0;
  font-size: 0.42667rem;
  border-color: #000000 solid 0.01rem;
}

.van-divider::after {
  border-width: 0.025rem 0 0 !important;
  border-color: #5a5a5a;
}
.van-divider--content-center::before, .van-divider--content-left::before, .van-divider--content-right::before {
    margin-right: 2.133vw;
    border-width: 0.025rem 0 0 !important;
    border-color: #5a5a5a;
}




.logoImg2 {
  width: 100%;
  height: 10%;
}
::v-deep .van-field__control{
  color: #000 !important;
}
</style>
