<template>
  <div class="container page setting">
      <van-nav-bar :title="$t('setting.setting')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#000" @click="back()"/>
        </template>
      </van-nav-bar>

      <div class="items">
        <div class="item van-hairline--bottom" @click="toInfomation()">
          <div class="left">{{$t("setting.base_setting")}}</div>
          <van-icon name="arrow" />
        </div>
        <div class="item van-hairline--bottom" @click="toLoginPassword()">
          <div class="left">{{$t("setting.login_pwd")}}</div>
          <van-icon name="arrow" />
        </div>
        <div class="item van-hairline--bottom" @click="toPayPassword()">
          <div class="left">{{$t("setting.finance_pwd")}}</div>
          <div class="right">
            <span class="desc">{{this.userInfo.paypassword}}</span>
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="item van-hairline--bottom" @click="toLanguage()">
          <div class="left">{{$t("setting.language")}}</div>
          <div class="right">
            <van-icon name="arrow" />
          </div>
        </div>
      </div>


      <van-button class="sign-out" type="primary" size="normal" @click="loginout()">{{$t("setting.logout")}}</van-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    toPayPassword(){
      if(this.userInfo.paypassword !== this.$t("setting.no_setting")){
        this.$toast(this.$t("withdraw.with_service"));
      }else {
        this.$router.push({path:'/SetPayPassword'});
      }
    },
    toLoginPassword(){
      this.$router.push({path:'/SetLoginPassword'});
    },

    toLanguage(){
      this.$router.push({
        name:'Language',
        params: {
          "type": "setting"
        }
      });
    },
    toInfomation(){
      this.$router.push({path:'/Infomation'});
    },
    loginout(){
        // localStorage.clear()
      localStorage.removeItem('iframeMap')
      localStorage.removeItem('passNextKeyKeys')
      localStorage.removeItem('waitForEnterForFilteredHints')
      localStorage.removeItem('settingsVersion')
      localStorage.removeItem('collectConfig')
      localStorage.removeItem('loglevel:webpack-dev-server')
      localStorage.removeItem('token')
        this.$router.push({path:'/Mine'});
    },
    toServicePage(){
      this.$router.push("ServicePage");
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          if(res.data.paypassword){
            this.userInfo.paypassword = this.$t("setting.y_setting");
          }else {
            this.userInfo.paypassword = this.$t("setting.no_setting");
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-nav-bar__title {
  color: #000 !important;
}
.setting .items {
  font-size: .37333rem;
  color: #fff;
  padding: 0 .37333rem;
}
.setting .items .item {
  padding: .37333rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.setting .sign-out {
  margin: .53333rem .4rem 0;
  width: calc(100% - .8rem);
  height: 1.22667rem;
  line-height: 1.22667rem;
  font-size: .42667rem;
  font-weight: bolder;
  border: 0.01rem solid #000;
  color: #000000;
  border-radius: 1rem;
  background: #fff;
}
::v-deep .nav-bar {
    background-color: #ffffff;
}
::v-deep .items{
  color: #000 !important;
}
::v-deep .van-nav-bar__left:first-child {
   color: #000 !important

}
</style>
