<template>
  <div class="container page">
    <van-nav-bar :title="$t('withdraw.with_center')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#000" @click="back()" />
      </template>
      <template #right>
        <van-icon name="wap-home-o" color="#000" @click="$router.push({ path: '/Mine' })" />
        <!--        <span class="nav-right" @click="$router.push({path:'/WithdrawRecord'})">{{$t("withdraw.with_record")}}</span>-->
      </template>
    </van-nav-bar>
    <div class="main">
      <div class="logoImg2">
        <img src="../../assets/imgs/logo5.png" height="80%" width="80%">
      </div>
      <div class="account">
        <div class="fontCon">
          <div class="displayFlex">
            <span class="width2">จำนวนบัญชี：</span>
            <span class="fontB">{{ userInfo.money }}</span>
          </div>
          <div class="displayFlex">
            <span class="width2">ความน่าเชื่อถือ：</span>
            <span class="fontB">{{ this.userInfo.reputation_points }}</span>
          </div>
        </div>
      </div>
      <div class="add-card" @click="toBindCard()" v-if="!is_bind">
        <van-icon name="plus" />
        <span>{{ $t("setting.add_bank") }}</span>
      </div>
      <div class="withdrawMoney">
        <span>{{ $t("withdraw.with_money") }} ({{ $t("my.currency") }})</span>
        <div class="money">
          <div class="moneyNumber">
            <span class="moneyType">{{ $t("my.currency") }}</span>
            <van-field v-model="withdraw_money" type="number" />
          </div>
          <span class="all" @click="allMoeny()">{{ $t("index.all") }}</span>
        </div>
      </div>
      <div class="petunjuk">แสดง:</div>
      <div class="petunjuk2">
        กรุณากรอกข้อมูลการถอนให้ถูกต้องและยืนยันการถอนเงินหลังจากยืนยันจำนวนเงินที่ถอนออกแล้ว
        ติวเตอร์จะแจ้งให้ทราบหลังจากการถอนเงินสำเร็จ
      </div>
       <van-button class="withdraw_btn" type="default" @click="doWithdraw()"> {{$t("withdraw.immediately_withdraw")}}</van-button>


      <!-- <div class="information">
          <div class="description">
            <van-popover v-model="showPopover" trigger="click">
              <div class="popover-body" style="padding: 10px;">
                <p>1.{{$t("withdraw.single_limit")}}{{$t("withdraw.low")}}{{this.withdrawRole.min}}{{$t("withdraw.heigh")}}{{this.withdrawRole.max}}{{$t("reservation.unit")}}</p>
                <p>2.{{$t("withdraw.with_num")}}{{this.withdrawRole.num}}{{$t("withdraw.number")}}</p>
                <p>3.{{$t("withdraw.with_tip")}}</p></div>
              <template #reference @click="withdrawInfo()">
                <van-icon name="info-o" />
                {{$t("withdraw.limit_desc")}}
              </template>
            </van-popover>
          </div>

          <div class="balance">
            <span>{{$t("my.balance")}}：</span>
            <span class="number">{{ this.userInfo.money }}  {{$t("reservation.unit")}}</span>
          </div>
        </div> -->
    </div>
    <!-- <van-button class="withdraw_btn" type="default" @click="doWithdraw()"> {{$t("withdraw.immediately_withdraw")}}</van-button> -->

  </div>

</template>

<script>
export default {
  data() {
    return {
      is_bind: true,
      showPopover: false,
      withdraw_money: "",
      userInfo: {},
      withdrawRole: {}
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    getUserBankInfo() {
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res => {
        if (res.code === 200) {
          if (res.data.is_bank) {
            this.is_bind = true;
          } else {
            this.is_bind = false;
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.name = res.data.name;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getUserWithdrawRole() {
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_role'
      }).then(res => {
        if (res.code === 200) {
          this.withdrawRole = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    allMoeny() {
      this.withdraw_money = this.userInfo.money;
    },
    doWithdraw() {
      //是否绑定银行卡
      if (!this.is_bind) {
        this.$router.push("BindCard?from=withdraw");
        return;
      }
      //检查是否设置资金密码
      if (!this.userInfo.paypassword) {
        this.$router.push("SetPayPassword?from=withdraw");
        return;
      }
      if (this.withdraw_money <= 0) {
        this.$toast(this.$t("setting.correct_money"));
        return false;
      } else {
        this.$http({
          method: 'post',
          data: { money: this.withdraw_money },
          url: 'user_set_withdraw'
        }).then(res => {
          if (res.code === 200) {
            location.reload()
            this.$toast(res.msg);
            this.getUserInfo();
            this.getUserWithdrawRole();
          } else if (res.code === 401) {
            this.$toast(res.msg);
          }
        })
      }
    },
    withdrawInfo() {
      this.showPopover = true;
    },
    toBindCard() {
      this.$router.push({ path: '/BindCard?from=withdraw' })
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserInfo();
      this.getUserWithdrawRole();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.petunjuk {
  font-weight: bold;
  margin: 1rem 0 0.5rem 0;
  font-size: 0.6rem;
  padding-left: 0.5rem
}

.petunjuk2 {
  font-size: 0.35rem;
  color: #8a8a8a;
  padding: 0 0.5rem;
}

.account {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // padding-left: 0.5rem;
  margin-top: 0.5rem;
  width: 100%;
  margin-bottom: 0.5rem;
  background: #000;
}

.displayFlex {
  font-size: 0.45rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;

  .width2 {

    display: flex;
    justify-content: flex-start;
  }

  .fontB {
    font-weight: bold;
  }
}

.fontCon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1rem;
}

.logoImg2 {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/.van-field__control {
  padding-left: 15px;
  color: #000 !important;
}

.van-cell {
  font-size: 35px;
  line-height: 80px;
}

.container p {
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}

.container .main {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: calc(100% - 50px);
  position: relative;
}

.container .main .withdrawMoney {
  display: flex;
  flex-direction: column;
  color: #000000;
  padding: 0 2.667vw;
  white-space: nowrap;
  font-size: 4.667vw;
  background-color: #ffffff;
}

.container .main .withdrawMoney span {
  padding: 10px 0;
}

.container .main .withdrawMoney .money {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #000000;
}

.container .main .withdrawMoney .money .moneyNumber {
  font-size: 50px;
  display: flex;
  flex-direction: row;
}

.container .main .withdrawMoney span {
  padding: 10px 0;
}

.container .main .withdrawMoney .money .all {
  color: #d10404;
}

.container .main .withdrawMoney .money .moneyNumber .van-cell {
  font-size: 50px;
  padding: 0 !important;
}

.container .main .withdrawMoney .information {
  padding-bottom: 30px;
}

.container .main .withdrawMoney .information .description {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}

.container .main .withdrawMoney span {
  padding: 10px 0;
}

.container .main .withdrawMoney .information .balance .number {
  color: #d10404;
}

.withdraw_btn {
  margin: .53333rem .4rem 0;
  width: calc(100% - .8rem);
  height: 1.22667rem;
  line-height: 1.22667rem;
  border-radius: 1rem;
  font-size: .42667rem;
  font-weight: bolder;
  border: 0.01rem solid #000;
  color: #000000;
  background: #fff;
}

.add-card {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: #fff;
  height: 100px;
  margin: 5px 0px;
}

.add-card span {
  margin-left: 10px;
  font-size: 30px;
}

/deep/body .van-toast {
  width: 100% !important;
}
</style>
