<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('recharge.recharge')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#000" @click="back()" />
        </template>
      </van-nav-bar>
    </div>

    <div class="main">
      <div>
        <img src="/img/mine/qb.png">
        <div>
          <span>{{ $t("recharge.curr_balance") }}</span><span>{{ this.balance }} ฿</span>
        </div>
      </div>

      <div ><div @click="doPay()" class="button">เติมเงินทันที</div></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      balance: 0,
      userInfo:{}
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.status !== 1){
            this.$toast(this.$t("video.account_out"));
            localStorage.clear()
            this.$router.push({path:'/Login'})
          }
          this.userInfo = res.data;
          this.balance = res.data.money;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    doPay(){
      this.$router.push({
        name:'Recharge',
        params:{
          'balance':this.userInfo.money
        }
      })
    }
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.getUserInfo();
    }
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.button{
  border: #000 0.05rem solid;
  
}
::v-deep .van-nav-bar__title {
  color: #000 !important;
}
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
}
.container .main{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  height: calc(100% - 1.5rem);
  padding: 0 .26667rem;
}
.nav-bar {
  background-color: #ffffff;
  height:auto;
  border-bottom: 0.02rem #000 solid;
}
.container .main>div:first-child {
  height: 3.2rem;
  margin: 1.86667rem auto 1.33333rem;
}
.container .main>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.container .main>div:first-child>span {
  font-size: .37333rem;
  color: #000000;
  font-weight: 700;
}
.container .main>div:first-child>div span {
  font-size: .37333rem;
  font-weight: 700;
  color: #000000;
}
.container .main>div:first-child>div>span:nth-child(2) {
  margin: 0 .4rem;
}
.container .main>div:nth-child(2)>div {
  width: 100%;
  height: 1.06667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .37333rem;
  border-radius: .05333rem;
}
.container .main>div:nth-child(2)>div:first-child {
  background-color: #ffffff;
  color: #000;
  border-radius: 1rem;
}
.container .main>div:nth-child(2) {
  width: 8rem;
  height: 2.66667rem;
}
</style>
