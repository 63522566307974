<template>
  <div class="mine page">
    <div class="logoImg">
      <img src="../../assets/imgs/logo1.png" height="100%" width="100%">
    </div>
    <div class="account">
      <div class="fontCon">
        <div class="displayFlex">
          <span class="width2">บัญชีสมาชิก：</span>
          <span class="fontB">{{this.userInfo.username}}</span>
        </div>
        <div class="displayFlex">
          <span class="width2">จำนวนบัญชี：</span>
          <span class="fontB">{{userInfo.money}}</span>
        </div>
        <div class="displayFlex">
          <span class="width2">ความน่าเชื่อถือ：</span>
          <span class="fontB">{{ this.userInfo.reputation_points }}</span>
        </div>
      </div>
      <div class="imgCon">
        <img src="../../assets/imgs/logo3.jpg" alt="" height="100%" width="100%">
      </div>
    </div>
    <div class="wrapper">
      <van-pull-refresh :border="false" class="list-wrapper" v-model="isLoading" @refresh="onRefresh">
        <div class="content">
          <div class="menu more_features_3">
            <div class="menu-item" @click="doWallet()">
              <div class="van-image menu-item-icon">
                <img src="/img/mine/qb.png" class="van-image__img" style="object-fit: contain;">
              </div>
              <span class="menu-item-label">ยอดเงินบัญชี </span>
              <i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>
            </div>
          </div>

          <div class="menu more_features_3">
            <div class="menu-item" @click="$router.push({ path: '/Transactions' });">
              <div class="van-image menu-item-icon">
                <img src="/img/mine/jilu.png" class="van-image__img" style="object-fit: contain;">
              </div>
              <span class="menu-item-label">ประวัติการทำรายการ</span>
              <i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>
            </div>
            <div class="menu-item" @click="doWithdrawal()">
              <div class="van-image menu-item-icon">
                <img src="/img/mine/czjl.png" class="van-image__img" style="object-fit: contain;">
              </div>
              <span class="menu-item-label">การถอนยอด</span>
              <i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>
            </div>
            <div class="menu-item" @click="$router.push({ path: '/Personalreport' });">
              <div class="van-image menu-item-icon">
                <img src="/img/mine/dl.png" class="van-image__img" style="object-fit: contain;">
              </div>
              <span class="menu-item-label">รายงานส่วนบุคคล</span>
              <i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>
            </div>
          </div>

          <div class="menu more_features_3">
            <div class="menu-item" @click="$router.push({ path: '/Setbank' });">
              <div class="van-image menu-item-icon">
                <img src="/img/mine/lc.png" class="van-image__img" style="object-fit: contain;">
              </div>
              <span class="menu-item-label">ข้อมูลเกี่ยวกับบัญชี</span>
              <i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>
            </div>
            <div class="menu-item" @click="showSetting()">
              <div class="van-image menu-item-icon">
                <img src="/img/mine/seting.png" class="van-image__img" style="object-fit: contain;">
              </div>
              <span class="menu-item-label">ปรับเปลี่ยนภาษา</span>
              <i class="van-badge__wrapper van-icon van-icon-arrow font-gray" style="font-size: 14px;"></i>
            </div>
          </div>
        </div>

      </van-pull-refresh>
    </div>
    <van-button class="button" @click="loginOut()">ออกจากระบบบัญชี </van-button>

  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      userInfo: {
      },
      menu_top: 40
    };
  },
  methods: {
    loginOut() {
      // localStorage.clear()
      localStorage.removeItem('iframeMap')
      localStorage.removeItem('passNextKeyKeys')
      localStorage.removeItem('waitForEnterForFilteredHints')
      localStorage.removeItem('settingsVersion')
      localStorage.removeItem('collectConfig')
      localStorage.removeItem('loglevel:webpack-dev-server')
      localStorage.removeItem('token')
      this.$router.push({ path: '/login' });
    },
    refresh() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem('token')) {
          this.$toast(this.$t("reservation.refresh"));
        } else {
          this.$router.push({ path: '/Login' })
        }
      }, 500);
    },
    exit() {
      this.$toast(this.$t("my.finish_task"));
    },
    showSetting() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Setting' })
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    toNotice() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Notice' })
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem('token')) {
          this.getUserInfo();
          this.$toast(this.$t("reservation.refresh"));
        } else {
          this.$router.push({ path: '/Login' })
        }
      }, 500);
    },
    doLogin() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Infomation' });
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    doWallet() {
      this.$router.push({
        name: 'Wallet'
      })
    },
    doWithdrawal() {
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res => {
        if (res.data.is_bank) {
          this.$router.push("withdraw");
        } else {
          this.$router.push("Setbank?from=withdraw");
          this.$toast.fail(this.$t("setting.set_bank"));
        }
      })
    },
    toService() {
      if (this.$store.getters.getBaseInfo.iskefu == 1) {
        this.$router.push("ServiceOnline");
      } else {
        this.$toast.fail(this.$t("setting.forbid"));
      }
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          console.log( this.userInfo,'11')
          this.menu_top = 15;
          if (this.userInfo.status !== 1) {
            this.$toast(this.$t("video.account_out"));
            localStorage.clear()
            this.$router.push({ path: '/Login' })
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
  },
  created() {
    if (localStorage.getItem('token')) {
      this.getUserInfo();
    } else {
      this.userInfo.username = this.$t("setting.log_reg");
      this.userInfo.ip = this.$t("setting.more_service");
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  }
};
</script>

<style scoped>
.displayFlex {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .width2 {
    width: 2.2rem;
  }

  .fontB {
    font-weight: bold;
  }
}

.button {
  background: #ffffff;
  color: #000;
  border-radius: 1rem;
  border: 0.05rem #000 solid;
  width: 50%;
  height: 1rem;
  font-size: 0.35rem;
  margin-top: 1rem;
}

.logoImg {
  display: flex;
  align-items: center;
  border-bottom: #000 solid 0.01rem;
}

.account {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5rem;
  margin-top: 0.5rem;
  width: 100%;
  margin-bottom: 0.5rem;
}

.fontCon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 0.7rem;
}

.imgCon {
  height: 2rem;
  width: 3.5rem;
}

.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
}

.mine {
  display: flex;
  flex-direction: column;
  align-items: center;
}







.mine .wrapper .pull-text {
  font-size: 0.34667rem;
  color: rgb(255, 255, 255);
}

.mine .wrapper .pull-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mine .wrapper .pull-loading .pull-loading-text {
  margin-left: 0.26667rem;
  font-size: 0.34667rem;
  color: rgb(255, 255, 255);
}

.mine .wrapper .content {
  position: relative;
}

.header {
  padding: 0.93333rem 0px 0.53333rem;
  background-color: rgb(255, 255, 255);
}

.header .nav {
  background-color: rgb(255, 255, 255);
}

.header .nav .right-icon {
  color: rgb(255, 255, 255);
}

.header .user-wrapper {
  display: flex;
  align-items: center;
  margin: 0px 0.4rem;
}

.header .user-wrapper .avatar-img {
  border: 0.05333rem solid rgb(255, 255, 255);
}

.header .user-wrapper .login-content {
  flex: 1 1 0%;
  margin-left: 0.4rem;
}

.header .user-wrapper .login-content .login-btn {
  display: inline-block;
  font-size: 0.48rem;
  line-height: 0.58667rem;
  color: rgb(255, 255, 255);
  font-weight: 500;
}

.header .user-wrapper .login-content .login-label {
  margin-top: 0.13333rem;
  line-height: 0.42667rem;
  font-size: 0.32rem;
  color: rgba(255, 255, 255, 0.6);
}

.header .user-wrapper .user-content {
  flex: 1 1 0%;
  margin-left: 0.4rem;
}

.header .user-wrapper .user-content .name {
  display: flex;
  align-items: center;
}

.header .user-wrapper .user-content .name .text {
  font-size: 0.37333rem;
  font-weight: 500;
  color: rgb(255, 255, 255);
}

.header .user-wrapper .user-content .name .level-icon {
  margin-left: 0.13333rem;
  width: 1.06667rem;
  height: 0.53333rem;
  object-fit: contain;
}

.header .user-wrapper .user-content .experience {
  display: flex;
  margin: 0.26667rem 0px;
  width: 80%;
  height: 0.10667rem;
  border-radius: 0.26667rem;
  background-color: rgba(255, 255, 255, 0.5);
  overflow: hidden;
}

.header .user-wrapper .user-content .experience .experience-content {
  height: 100%;
  border-radius: 0.26667rem;
  background-color: rgb(255, 255, 255);
}

.header .user-wrapper .user-content .experience-text {
  font-size: 0.32rem;
  font-weight: 500;
  color: rgb(255, 255, 255);
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.header .user-wrapper .use-content-name {
  color: rgb(255, 255, 255);
  margin-left: 0.2rem;
  font-size: 0.42667rem;
}


.more_features_1 {
  margin-left: 0.26667rem;
  margin-right: 0.26667rem;
  justify-content: flex-start;
  width: calc(100% - 0.53333rem) !important;
  box-shadow: none !important;
}

.more_features_1 .menu-item {
  flex-direction: column;
  width: 33.33% !important;
  height: 3.12rem !important;
  justify-content: center !important;
}

.more_features_1 .menu-item .menu-item-icon {
  width: auto !important;
  height: 1.06667rem !important;
  object-fit: contain !important;
  margin-bottom: 0.13333rem !important;
}

.more_features_1 .menu-item:not(:nth-child(3n)) {
  border-right: 0.02667rem solid rgba(204, 204, 204, 0.3);
}

.more_features_1 .menu-item:not(:first-child, :nth-child(2), :nth-child(3)) {
  border-top: 0.02667rem solid rgba(204, 204, 204, 0.3);
}

.more_features_2 {
  margin-left: 0.26667rem;
  margin-right: 0.26667rem;
  width: calc(100% - 0.53333rem) !important;
  box-shadow: none !important;
}

.more_features_2 .menu-item {
  width: calc(100% - 0.53333rem) !important;
  height: 1.2rem !important;
  margin: auto !important;
  justify-content: space-between !important;
}

.more_features_2 .menu-item .menu-item-label {
  flex: 1 1 0%;
  display: block;
  text-align: left;
}

.more_features_2 .menu-item .menu-item-icon {
  margin-left: 0px !important;
}

.more_features_2 .menu-item:not(:last-child) {
  border-bottom: 0.02667rem solid rgba(204, 204, 204, 0.3);
}

.more_features_3,
.more_features_4 {
  box-shadow: none !important;
}

.more_features_3 .menu-item,
.more_features_4 .menu-item {
  width: calc(100% - 0.53333rem) !important;
  height: 1.2rem !important;
  margin: auto !important;
  justify-content: space-between !important;
}

.more_features_3 .menu-item .menu-item-label,
.more_features_4 .menu-item .menu-item-label {
  flex: 1 1 0%;
  display: block;
  text-align: left;
}

.more_features_3 .menu-item .menu-item-icon,
.more_features_4 .menu-item .menu-item-icon {
  margin-left: 0px !important;
}

.more_features_3 .menu-item:not(:last-child),
.more_features_4 .menu-item:not(:last-child) {
  border-bottom: 0.02667rem solid rgba(204, 204, 204, 0.3);
}

.more_features_5 {
  margin-left: 0.26667rem;
  margin-right: 0.26667rem;
  width: calc(100% - 0.53333rem) !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.more_features_5 .menu-item {
  width: calc(100% - 0.53333rem) !important;
  height: 1.2rem !important;
  margin: auto !important;
  justify-content: space-between !important;
}

.more_features_5 .menu-item .menu-item-label {
  flex: 1 1 0%;
  display: block;
  text-align: left;
}

.more_features_5 .menu-item .menu-item-icon {
  margin-left: 0px !important;
}

.more_features_5 .menu-item:not(:last-child) {
  border-bottom: 0.02667rem solid rgba(204, 204, 204, 0.3);
}

.more_features_5 .menu-item {
  padding: 0px 0.21333rem;
  width: 100% !important;
  height: 0.93333rem !important;
  background-color: rgba(42, 35, 44, 0.6) !important;
  border: none !important;
  margin-bottom: 0.13333rem !important;
}

.more_features_5 .menu-item .menu-item-icon {
  object-fit: contain;
  margin-right: 0.13333rem !important;
  height: 0.48rem !important;
}

.more_features_5 .menu-item .menu-item-label,
.more_features_5 .menu-item i {
  color: rgb(0, 0, 0) !important;
}

.menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: rgb(255, 255, 255);
}

.menu .menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 1.86667rem;
  color: rgb(0, 0, 0);
}

.menu .menu-item .menu-item-icon {
  margin-right: 0.26667rem;
  width: 0.53333rem;
  object-fit: contain;
}

.menu .menu-item .menu-item-label {
  font-size: 0.34667rem;
  font-weight: 500;
}
</style>
