<template>
  <div class="home-container">
    <div class="logoCon">
      <div class="logo4">
        <img src="../../assets/imgs/logo4.png" alt="" width="100%" height="100%">
      </div>
      <div class="logo3">
        <img src="../../assets/imgs/logo3.jpg" alt="" width="100%" height="100%">
      </div>
    </div>
    <div class="img-list-con" v-for="(item, index) in imgList" :key="index">
      <div class="img-list">
        <img :src="item.img" alt="" class="listImg">
        <span class="font1">{{ item.font1 }}</span>
        <span class="font2">{{ item.font2 }}</span>
        <van-button class="imgListButton" @click="handle(item.url)">Look for</van-button>
      </div>
    </div>
    <div class="img-list-con">
      <div class="img-list">
<!--        <video  src="../../assets/shipin.mp4" width="100%" autoplay="autoplay"  muted="muted"></video>-->
        <video autoplay="autoplay" loop="loop" muted width="100%" height="100%" src="../../assets/shipin.mp4"></video>
        <span class="font1">YSL BEAUTY THAILAND 2567</span>
        <span class="font2" style="padding: 0 0.3rem;">เพื่อกิจวัตรความงามที่สมบูรณ์แบบสำหรับคุณและคนที่คุณรัก สมัครเป็นสมาชิก YSL เพื่อรับ
          รับรางวัลและสิทธิประโยชน์พิเศษโดยรับคะแนนทุกครั้งที่คุณซื้อสินค้าออนไลน์หรือที่เคาน์เตอร์ห้างสรรพสินค้าแห่งใดแห่งหนึ่งของเราทั่วประเทศ
          เข้าร่วม YSL Beauty Club วันนี้เพื่อรับข้อเสนอสุดพิเศษ ของรางวัล และประสบการณ์สุดพิเศษสำหรับสมาชิกเท่านั้นที่
          YSL Beauty Thailand</span>
        <van-button class="imgListButton" @click="handle('https://www.yslbeautyus.com/our-news.html')">
        Look  for</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from '../../assets/imgs/img1.png'
import img2 from '../../assets/imgs/img2.png'
import img3 from '../../assets/imgs/img3.png'
import img4 from '../../assets/imgs/img4.png'
import img5 from '../../assets/imgs/img5.png'
import img6 from '../../assets/imgs/img6.png'
// import videojs from 'video.js'
// import 'videojs-contrib-hls'

export default {
  data() {
    return {
      imgList: [
        { img: img1, font1: 'PARFUM', font2: 'Y EAU DE PARFUM INTENSE', url: 'https://www.yslbeautyus.com/new-now/featured-shops/bestsellers/' },
        { img: img2, font1: 'MAKEUP', font2: 'ค้นพบเครื่องสำอางจาก YSL BEAUTY เครื่องสำอางสำหรับแต่งหน้า ดวงตาและริมฝีปาก', url: 'https://www.yslbeautyus.com/makeup/face-makeup/' },
        { img: img3, font1: 'BEST SELLING ICONS OF YSL BEAUTY', font2: 'MOST SOUGHT-AFTER BEAUTY TRENDS', url: 'https://www.yslbeautyus.com/makeup/lips/' },
        { img: img4, font1: 'LIPSTICK', font2: 'YSL LOVESHINE', url: 'https://www.yslbeautyus.com/makeup-collection.html' },
        { img: img5, font1: 'MAKEUP', font2: 'S K I N C A R E', url: 'https://www.yslbeautyus.com/skincare/' },
        { img: img6, font1: 'GIFT SETS', font2: 'SUMMER ESSENTIALS BUNDLE', url: 'https://www.yslbeautyus.com/new-now/gift-sets/' },
      ]
    };
  },
  mounted() {


    // videojs("my-video",
    //   function () {
    //     this.play();
    //   });


  },
  methods: {
    handle(url) {
      window.location.href = url
    }
  },
}


</script>

<style>
.home-container {


  background-color: #fff;
  padding-bottom: 1.5rem;
}



.logoCon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1rem;
  background: #fff;

  .logo4 {
    width: 50%;
    height: 100%;
  }

  .logo3 {
    width: 20%;
    height: 100%;
  }
}

.img-list-con {


  .img-list {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .listImg {
    height: 3rem;
    width: 100%;
  }

  .font1 {
    display: block;
    font-size: 0.3rem;
    color: #908c8c;
    margin: 0.3rem 0;
    text-align: center;

  }

  .font2 {
    display: block;
    font-size: 0.4rem;
    color: #000;
    text-align: center;

  }

  .imgListButton {
    text-align: center;
    border: 0.05rem solid #000;
    border-radius: 1rem;
    width: 3rem;
    height: 1rem;
    font-size: 0.35rem;
    margin: 0.3rem 0 0.4rem 0;
  }
}
</style>

//
<style lang='less' scoped>
// .home-container{
//   height: calc(100% - 1.33333rem)
// }

// .home-container .home-scroll{
//   width: calc(100% - .53333rem);
//   height: calc(100% - 1.06667rem);
//   margin: 0 auto;
//   overflow-y: auto
// }

// .home-container .banner{
//   height: 4.1984rem;
//   position: relative
// }

// .home-container .banner .position-btn{
//   position: absolute;
//   top: 3.46667rem;
//   right: .13333rem;
//   z-index: 1000000;
//   height: .53333rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: .26667rem;
//   font-size: .34667rem;
//   padding: 0 .24rem;
//   word-break: keep-all;
//   background: linear-gradient(#fde3ca,#e7b486);
//   color: #8a5623
// }


// .home-top-bar{
//   width: 100%;
//   height: 1.06667rem;
//   padding: 0 .4rem 0 .26667rem;
//   display: flex;
//   align-items: center;
//   justify-content: space-between
// }

// .home-top-bar img{
//   height: 1.06667rem;
//   -o-object-fit: contain;
//   object-fit: contain
// }

// .home-top-bar span{
//   color: #f3d3b8
// }

// .home-top-bar span:first-child {
//   font-size: .34667rem
// }

// .home-top-bar span:nth-child(2) {
//   font-size: .48rem;
//   font-weight: 700
// }

// .home-top-bar span:nth-child(3) {
//   font-size: .34667rem;
//   color: transparent
// }

// .home-top-bar i{
//   color: #fff
// }
// .swiper-container,.swiper-slide,.banner_img{
//   height: 100%;
//   width: 100%;
// }

// .home-container .home-scroll{
//   width: calc(100% - .53333rem);
//   height: calc(100% - 1.06667rem);
//   margin: 0 auto;
//   overflow-y: auto
// }
// .home-container .banner{
//   height: 4.1984rem;
//   position: relative
// }

// .home-container .banner .position-btn{
//   position: absolute;
//   top: 3.46667rem;
//   right: .13333rem;
//   z-index: 1000000;
//   height: .53333rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: .26667rem;
//   font-size: .34667rem;
//   padding: 0 .24rem;
//   word-break: keep-all;
//   background: linear-gradient(#fde3ca,#e7b486);
//   color: #8a5623
// }
// .banner .swiper-container .swiper-wrapper .swiper-slide .padding-b .img-wrapper{
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
// }
// .van-image--round {
//   overflow: hidden;
//   border-radius:0;
// }


// .describe {
//   width: 100%;
//   min-height: 5rem;
//   border-radius: .26667rem;
//   border: .02667rem solid #766350;
//   margin: 0.3rem 0;
//   margin-top: 1.26667rem;
//   background: linear-gradient(120deg,#212121,#313131);
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   padding: .21333rem;
//   position: relative;
//   overflow: hidden
// }

// .describe>ul {
//   height: 2.13333rem;
//   display: flex;
//   align-items: flex-start
// }

// .describe>ul li {
//   height: 100%
// }

// .describe>ul>li:first-child {
//   width: 3.2rem;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: flex-start;
//   border-right: .08rem solid #e6b98a
// }

// .describe>ul>li:first-child div {
//   color: #f3d3b8;
//   height: 25%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: .32rem
// }

// .describe>ul>li:first-child>div:first-child {
//   font-size: .42667rem;
//   font-weight: 700;
//   height: 1.06667rem;
//   text-align: center
// }

// .describe>ul>li:nth-child(2) {
//   padding: 0 0 0 .13333rem;
//   width: calc(100% - 3.2rem);
//   font-size: .32rem;
//   line-height: .66667rem;
//   color: #fff
// }

// .describe>div {
//   height: 1.6rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   color: #e6b98a;
//   line-height: .64rem;
//   font-size: .32rem
// }

// .describe>div:nth-child(3) {
//   font-size: .4rem;
//   height: .8rem!important;
//   font-weight: 700
// }

// .describe>div:nth-child(4) {
//   width: 3.2rem;
//   height: .8rem;
//   background-color: #e6b98a;
//   color: #fff;
//   font-size: .48rem;
//   border-radius: .4rem;
//   margin: .13333rem auto;
//   font-weight: 700
// }

// .describe:after,.describe:before {
//   content: "";
//   position: absolute;
//   width: .53333rem;
//   height: 6.66667rem;
//   background: linear-gradient(90deg,rgba(74,70,70,.29),transparent);
//   transform: rotate(50deg)
// }

// .describe:after {
//   left: 50%
// }



// .home-body{
//   width: 100%
// }

// .home-body>ul{
//   height: .8rem;
//   display: flex;
//   overflow: auto
// }

// .home-body>ul>li{
//   height: .53333rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: .26667rem;
//   background: linear-gradient(90deg,#5b606c,#2b2b2b);
//   font-size: .34667rem;
//   padding: 0 .1rem;
//   color: #fff;
//   word-break: keep-all
// }

// .home-body>ul>li.active{
//   background: linear-gradient(#fde3ca,#e7b486);
//   color: #8a5623
// }

// .home-body>ul>li:not(:last-child) {
//   margin-right: .13333rem
// }




// .xuanfei{
//   width: 100%;
//   height: 4rem;
//   border-radius: .26667rem;
//   border: .02667rem solid #766350;
//   margin-bottom: .26667rem;
//   background: linear-gradient(120deg,#212121,#313131);
//   display: flex;
//   justify-content: space-between;
//   padding: .21333rem;
//   position: relative;
//   overflow: hidden
// }

// .xuanfei .left-div{
//   width: 5.33333rem;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: center
// }

// .xuanfei .left-div>div:first-child {
//   background: linear-gradient(90deg,#efcba7,#dcab76);
//   color: #8d5825;
//   height: .53333rem;
//   padding: 0 .4rem;
//   border-radius: .26667rem;
//   line-height: .53333rem;
//   text-align: center;
//   font-size: .42667rem
// }

// .xuanfei .left-div>div:nth-child(2) {
//   margin: .21333rem 0;
//   padding-left: .26667rem
// }

// .xuanfei .left-div>div:nth-child(2) ul li{
//   height: .48rem;
//   line-height: .48rem;
//   text-align: left;
//   font-size: .34667rem;
//   color: #fff
// }

// .xuanfei .left-div>div:nth-child(3) ul{
//   display: flex
// }

// .xuanfei .left-div>div:nth-child(3) ul li{
//   display: flex;
//   align-items: center;
//   justify-content: flex-start
// }

// .xuanfei .left-div>div:nth-child(3) ul li img{
//   height: .34667rem;
//   margin-right: .05333rem
// }

// .xuanfei .left-div>div:nth-child(3) ul li span{
//   font-size: .32rem;
//   color: #696969
// }

// .xuanfei .left-div>div:nth-child(3) ul>li:first-child {
//   margin-right: .13333rem
// }

// .xuanfei .right-div{
//   width: 3.2rem;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center
// }

// .xuanfei .right-div img{
//   width: 2.66667rem;
//   height: 2.66667rem;
//   -o-object-fit: cover;
//   object-fit: cover;
//   border-radius: 50%
// }

// .xuanfei .sign-i{
//   position: absolute;
//   right: -.64rem;
//   top: .16rem;
//   background: #ff6056;
//   font-size: .32rem;
//   padding: .10667rem .64rem;
//   font-style: normal;
//   transform: rotate(45deg);
//   color: #fff
// }

// .scroll-cmp {
//   width: 100%;
//   height: 100%;
//   border-radius: 0.26667rem;
//   border: 0.02667rem solid #766350;
//   background: linear-gradient(120deg, #212121, #313131);
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   padding: 0.21333rem;
//   position: relative;
//   overflow: hidden;
// }
// .scroll-cmp .swper_item_detail{
//   height: 25%;
// }
// .serverTerms {
//   width: 100%;
//   min-height: 4rem;
//   border-radius: 0.26667rem;
//   border: 0.02667rem solid #766350;
//   margin: 0.26667rem 0;
//   background: linear-gradient(120deg, #212121, #313131);
//   display: flex;
//   align-items: center;
//   padding: 0.21333rem;
//   position: relative;
//   overflow: hidden;
// }
// .serverTerms ul li>div:first-child {
//   font-size: .42667rem;
//   color: #f3d3b8;
//   font-weight: 700
// }

// .serverTerms ul li>div:nth-child(2) {
//   font-size: .34667rem;
//   color: #fff
// }

// .serverTerms ul>li:not(:last-child) {
//   margin-bottom: .08rem
// }

// .serverTerms:after,.serverTerms:before {
//   content: "";
//   position: absolute;
//   width: .53333rem;
//   height: 6.66667rem;
//   background: linear-gradient(90deg,rgba(74,70,70,.29),transparent);
//   transform: rotate(50deg)
// }

// .serverTerms:after {
//   left: 50%
// }
// </style>
