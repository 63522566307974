<template>
  <div class="welfare-container">
    <img src="../../assets//imgs/3.png" alt="" class="logo">
    <span class="font1">โปรดอ่านกฎของทางร้านอย่างละเอียด</span>
    <span class="font2">
      1.งานตามลำดับตารางผลิตภัณฑ์จะถูกสุ่มให้กับลูกค้าแต่ละรายเพื่อหลีกเลี่ยงกิจกรรมที่ผิดกฎหมาย เช่น
      การฟอกเงินและการยักยอกเงินเพื่อละเมิดกฎหมาย
      ลูกค้าจะต้องทำงานให้เสร็จหลังจากข้อมูลงานเริ่มต้นและไม่สามารถยกเลิกงานได้ ระหว่างทำได้ หากทำการยกเลิกงานระหว่างทำ
      ระบบจะไม่อนุญาตให้ Recall กลับ
      <br>
      2. เปอร์เซ็นต์รายได้ของแต่ละคำสั่งซื้อจะถูกสุ่มจัดสรรโดยตารางงานที่กำหนดโดยระบบ<br>
      3. หากลูกค้ามีการละเมิดคะแนนจะลดลง หากคะแนนต่ำเกินไป ระบบจะไม่สามารถให้งานได้และจะไม่อนุญาตให้ถอนเงิน<br>
      4. การถอนเงินเข้าบัญชีธนาคารของลูกค้าจะใช้เวลา 1-5 นาที หากมีข้อผิดพลาดของทางธนาคารการถอนเงินอาจใช้เวลา 1-24<br>
      ชั่วโมง<br>
      5. ระบบการเงินบัญชีของเราทำงานเฉพาะในช่วงเวลาทำงาน ตั้งแต่ 10:00 น. ถึง 22:00 น.<br>
      6.ลูกค้าควรตรวจสอบยอดคงเหลือของแต่ละงานเมื่อทำการถอนเงินกรุณาทำการถอนเงินตามยอดเงินของงานนั้นๆ
      และแจ้งให้ผู้ดำเนินงานทราบหลังจากถอนเสร็จสิ้น กรุณาอย่าถอนเงินระหว่างรอค่าคอมมิชชั่นเข้า และเวลาระหว่างการทำการ
      อย่าคลิกคำขอถอนเงินซ้ำๆ<br>
      7.ลูกค้าจะต้องอ่านและทำความเข้าใจรายละเอียดงานที่ได้รับมา หากลูกค้าตกลงตามรายละเอียดงานที่ให้มา
      ลูกค้าจะต้องดำเนินการสั่งซื้อให้เสร็จสิ้นตามที่ตกลงกันไว้<br>
      8. อิงจากข้อกฏหมาย 2560 (รัฐธรรมนูญ) หมวด 3 ข้อ 1<br>
      9. หากพบความผิดปกติใดๆ ในงานคำสั่งซื้อ เครดิตของคุณจะลดลงโดยอัตโนมัติ<br>
    </span>
    <span class="font3">
      @1962-2024 YSL Yves Saint Laurent FR</span>
    <span class="font4">ตลาดเครื่องสำอางความสวยความงามของไทยกำลังฟื้นตัวเต็มที่ ความต้องการผลิตภัณฑ์ในอุตสาหกรรมความงามเติบโต 9.5% เมื่อเทียบเป็นรายปี มูลค่ารวม 149,000 ล้านบาท</span>
  </div>

</template>
<script>
export default {
  data() {
    return {

    };
  },


}
</script>

<style scoped>
.welfare-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow-y: auto; */
  padding-bottom: 2rem;
}

.logo {
  width: 80%;
}

.font1 {
  margin: 0.5rem 0;
  font-size: 0.3rem;
}

.font2 {
  margin: 0 0.7rem;
  line-height: 0.5rem;
  font-size: 0.3rem;
}

.font3 {
  margin: 0.5rem 0;
  font-size: 0.3rem;
}

.font4 {
  margin: 0 0.7rem;
  line-height: 0.5rem;
  font-size: 0.3rem;
}
</style>
