<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('my.my_statement')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#000" @click="back()"/>
        </template>
      </van-nav-bar>
      <div class="info">
        <p class="title">{{$t("withdraw.task_money")}}({{$t("reservation.unit")}})</p>
        <p class="value">{{ this.win_money.toFixed(2) }}</p>
        <p class="tip">{{$t("reservation.win_formula")}}</p>
        <p style="width: 80%">
          <van-field
              readonly
              clickable
              name="calendar"
              :value="search_start_date"
              :placeholder="$t('agent.date')"
              @click="showStartPicker = true"
          />
          <van-calendar v-model="showStartPicker"
                        :allow-same-day="true"
                        :min-date="minDate"
                        @confirm="onConfirm"/>
        </p>
      </div>

      <div class="content">
        <div class="datalist">
          <div class="datalistitem">
            <div class="datalistitemValue">{{ this.personalreport.play_money }}</div>
            <div class="datalistitemKey">{{$t("withdraw.task_money")}}</div>
            <div class="datalistitemRightLine"></div>
          </div>
          <div class="datalistitem">
            <div class="datalistitemValue">{{ this.personalreport.recharge}}</div>
            <div class="datalistitemKey">{{$t("withdraw.recharge_money")}}</div>
            <div class="datalistitemRightLine"></div>
          </div>
          <div class="datalistitem">
            <div class="datalistitemValue">{{ this.personalreport.withdrawal }}</div>
            <div class="datalistitemKey">{{$t("withdraw.money")}}</div>
            <div class="datalistitemRightLine"></div>
          </div>
          <div class="datalistitem">
            <div class="datalistitemValue">{{ this.personalreport.win_money }}</div>
            <div class="datalistitemKey">{{$t("withdraw.win_money")}}</div>
            <div class="datalistitemRightLine"></div>
          </div>

          <div class="datalistitem" v-if="agent">
            <div class="datalistitemValue">{{this.personalreport.register_today}}</div>
            <div class="datalistitemKey">{{$t("agent.register_today")}}</div>
            <div class="datalistitemRightLine"></div>
          </div>
          <div class="datalistitem" v-if="agent">
            <div class="datalistitemValue">{{this.personalreport.first_deposit_day}}</div>
            <div class="datalistitemKey">{{$t("agent.first_deposit_day")}}</div>
            <div class="datalistitemRightLine"></div>
          </div>
          <div class="datalistitem" v-if="agent">
            <div class="datalistitemValue">{{this.personalreport.total_recharge_day}}</div>
            <div class="datalistitemKey">{{$t("agent.total_recharge_day")}}</div>
            <div class="datalistitemRightLine"></div>
          </div>
          <div class="datalistitem" v-if="agent">
            <div class="datalistitemValue">{{this.personalreport.total_withdrawals_day}}</div>
            <div class="datalistitemKey">{{$t("agent.total_withdrawals_day")}}</div>
            <div class="datalistitemRightLine"></div>
          </div>
          <div class="datalistitem" v-if="agent">
            <div class="datalistitemValue">{{this.personalreport.recharge_difference_day}}</div>
            <div class="datalistitemKey">{{$t("agent.recharge_difference_day")}}</div>
            <div class="datalistitemRightLine"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      win_money:0,
      personalreport:{},
      agent:0,
      show:false,
      date:'',
      showStartPicker:false,
      search_start_date:'',
    };
  },
  computed: {
    minDate () {
      const date = new Date();
      return new Date(date.getFullYear()-1, date.getMonth() , date.getDate());
    }
  },
  methods: {
    back(){
      return window.history.back();
    },
    getPersonalreport(){
      this.$http({
        method: 'get',
        data:{date:this.search_start_date},
        url: 'user_get_personalreport'
      }).then(res=>{
        if(res.code === 200){
          this.personalreport = res.data;
          this.agent = this.personalreport.agent;
          this.win_money = this.personalreport.win_money - this.personalreport.play_money;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      this.search_start_date = `${this.formatDate(date)}`;
      this.showStartPicker = false;
      this.getPersonalreport();
    },
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getPersonalreport();
    }
  }
};
</script>

<style lang='less'>
@import "../../assets/css/base.css";
//.container .header{
//  background: linear-gradient(#fbf0fb,#f4b4fb);
//}
.container .header .info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 10px;
  margin: auto;
}
.container .header .info .title{
  font-size: 25px;
  color: #6a6a6a;
}
.container .header .info .value{
  margin: 10px auto;
  color: #6a6a6a;
  font-size: 50px;
  border-bottom: 1px solid #000000;
}
.container .header .info .tip{
  font-size: 30px;
  color: #6a6a6a;
}
.container .content{
  flex: 1;
  //background: #f2f2f5;
}
.container .content .datalist{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.container .content .datalist .datalistitem{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 33.3%;
}
.container .content .datalist .datalistitem .datalistitemValue{
  color: #000000;
  font-size: 35px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.container .content .datalist .datalistitem .datalistitemKey{
  color: #6c6c6c;
  font-size: 25px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.info .van-field__control{
  text-align: center;
}
</style>
