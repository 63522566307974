<template>
    <div class="register page">
      <van-nav-bar class="nav-bar" :title="$t('auth.register')">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
      <div class="wrapper">
        <div class="logoImg">
        <img src="../../assets/imgs/logo1.png" height="80%" width="80%" style="margin-left: -1rem;">
      </div>
      <div class="logoImg2">
        <img src="../../assets/imgs/logo2.png" height="100%" width="100%">
      </div>
        <div class="loginForm">
          <van-field v-model="username"  clearable input-align="center" class="input"  placeholder="กรอกยูสเซอร์" />
          <van-field
              v-model="password"
              :type="passwordType"
              input-align="center"
              class="input"
              placeholder="ใส่รหัสผ่าน"

          >
            <template slot="right-icon">
              <van-icon :name="passwordType === 'password' ? 'closed-eye':'eye-o'" @click="switchPasswordType"/>
            </template>
          </van-field>
          <van-field v-model="code"  clearable input-align="center" class="input"  placeholder="รหัสเชิญ" />
<!--          <van-field  clearable input-align="center" class="input"  placeholder="กรอกรหัสเชิญ" />-->
          <div class="agreement">
            <van-checkbox v-model="checked" />
            <span class="agreement-text">{{$t("auth.agreement_place")}}</span>
          </div>
          <van-button class="reg-btn"  type="primary" size="normal" @click="doRegister()"> ลงทะเบียน</van-button>
          <!-- {{$t("auth.register")}} -->
          <span class="tobBack" @click="handel">กลับสู่หน้าเข้าสู่ระบบ</span>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'input'
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checked: true,
      username: '',
      code: '',
      lang: 'en_us',
      password: this.inputValue,
      passwordType: 'password',
    };
  },
  mounted(){
    this.lang = localStorage.getItem("lang") || 'th_th';
  },
  methods: {
    handel(){
      this.$router.push('/login')
    },
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    back(){
      return window.history.back();
    },
    doRegister() {
      if(this.username === "" || this.username === null || this.username === undefined){
        this.$toast.fail(this.$t("auth.username_place"));
        return false;
      }
      if(this.password === "" || this.password === null || this.password === undefined){
        this.$toast.fail(this.$t("auth.pwd_place"));
        return false;
      }
      if(this.code === "" || this.code === null || this.code === undefined){
        this.$toast.fail(this.$t("auth.invite_code_place"));
        return false;
      }
      if(!this.checked){
        this.$toast.fail(this.$t("auth.agreement"));
        return false;
      }
      this.$http({
        method: 'post',
        data:{
          username:this.username,
          password:this.password,
          code:this.code,
          lang: this.lang
        },
        url: 'member_register'
      }).then(res=>{
	console.log(res);
          if(res.code === 200){
            this.$toast.success(res.msg);
            localStorage.setItem('token',res.data)
            this.$router.push("Mine")
          }else {
            this.$toast.fail(res.msg);
          }
      })
    }
  },
  created() {
    if(localStorage.getItem('token')){
      return window.history.back();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-field__control{
  color: #000 !important;
}
.tobBack{
  display: block;
  text-align: center;
  margin-top: 0.3rem;
  color: #5c5c5c;
  text-decoration-line: underline;
}
.register{
  height: 100%;
  background-color: #fff;
}
.register .wrapper {
  height: calc(100% - 1.22667rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch
}

.register .wrapper .title {
  line-height: 1.86667rem;
  text-align: center;
  font-size: .48rem;
  font-weight: 700;
  color: #fff;
  letter-spacing: .05333rem
}

.register .wrapper .login-input-border .input {
  border: .02667rem solid #fff
}

.register .wrapper .loginForm {
  margin: 1.06667rem 0;
  padding: 0 .53333rem
}

.register .wrapper .loginForm .input {
  padding: .26667rem .4rem;
  text-align: center;
  line-height: .53333rem;
  font-size: .34667rem;
  color: #4e4e4e;
  background-color: transparent;
  border-bottom: .02667rem solid #424041
}

.register .wrapper .loginForm .input.margin-t-20 {
  margin-top: .53333rem
}

.register .wrapper .loginForm .input.sms-input {
  padding: 0 0 0 .4rem;
  line-height: 1.06667rem
}

.register .wrapper .loginForm .input .right-icon {
  color: #979799
}

.register .wrapper .loginForm .input .sms-btn {
  padding: 0 .4rem;
  height: 100%;
  color: #fff;
  border-radius: 1.33333rem 0 0 1.33333rem;
  font-size: .32rem;
  font-weight: 500;
  box-shadow: 0 0 .21333rem 0 #e6b98a;
  background-color: #e6b98a
}

.register .wrapper .loginForm .input .sms-num {
  width: 1.06667rem;
  color: #8d8c8c
}

.register .wrapper .loginForm .input .phone-code {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%
}

.register .wrapper .loginForm .input .phone-code span {
  font-size: .34667rem;
  line-height: .37333rem;
  color: #979799;
  font-weight: 500
}

.register .wrapper .loginForm .agreement {
  margin-top: .53333rem;
  display: flex;
  align-items: center;
  justify-content: center
}

.register .wrapper .loginForm .agreement .agreement-text {
  margin-left: .13333rem;
  font-size: .29333rem;
  color: #000;
  font-weight: 500;
  line-height: .42667rem
}

.register .wrapper .loginForm .reg-btn {
  margin-top: .4rem;
  width: 100%;
  height: 1.22667rem;
  line-height: 1.22667rem;
  background: #000;
  color: #fff;
  font-size: .42667rem;
  font-weight: bolder;
  border: none ;
border-radius: 1rem;
}

.register .wrapper .loginForm .reg-btn.active {
  color: #fff;
  background: linear-gradient(#fde3ca,#e7b486);
  box-shadow: 0 0 .10667rem .02667rem #f2f2f5
}
.logoImg2 {
  width: 100%;
  height: 10%;
}
</style>
